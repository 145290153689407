/**
 * @param  {string} [url=window.location] url
 */
export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // we'll store the parameters here
  const obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // in case params look like: list[]=thing1&list[]=thing2
      let paramNum = null
      const paramName = a[0].replace(/\[\d*\]/, (v) => {
        paramNum = v.slice(1, -1)
        return ''
      })

      // set parameter value (use 'true' if empty)
      const paramValue = typeof a[1] === 'undefined' ? true : a[1]

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]]
        }
        // if no array index number specified...
        if (paramNum === null) {
          // put the value on the end of the array
          obj[paramName].push(paramValue)
        } else {
          // if array index number specified...
          // put the value at that index number
          obj[paramName][paramNum] = paramValue
        }
      } else {
        // if param name doesn't exist yet, set it
        obj[paramName] = paramValue
      }
    }
  }

  return obj
}

export function renameObjPropNames(obj, oldName, newName) {
  if (!obj.hasOwnProperty(oldName)) {
    return false
  }

  obj[newName] = obj[oldName]
  delete obj[oldName]
  return true
}

/**
 * @param {userRole} - role of current user
 * @param {...roles} - gets Roles from arguments
 * @returns true if current user approach with any of
 */

export function oneOfRoles(userRole, ...roles) {
  return roles.some((role) => role === userRole)
}

export const run = (element, acceleration, offset) => {
  let timer
  let current = window.pageYOffset
  let to

  if (typeof element === 'object') {
    to = element.getBoundingClientRect().top
    if (current < to) {
      to = offset ? to - offset : to + element.getBoundingClientRect().height
    } else {
      to = to - 30
    }
  } else {
    to = 0
  }
  return function (animation) {
    // boolean
    let point
    let clear
    if (animation) return window.scrollTo(0, to) // without animation;

    if (current < to) {
      point = acceleration || 5
      clear = function (from, to) {
        if (from > to) {
          clearTimeout(timer)
        }
      }
    } else {
      point = -acceleration || -5
      clear = function (from, to) {
        if (from < to) {
          clearTimeout(timer)
        }
      }
    }

    timer = setInterval(function () {
      current += point
      window.scrollTo(0, current)
      clear(current, to)
    }, 5)
  }
}

export const defaultValue = (obj, property, value = '') => {
  if (!obj || !obj[property]) {
    return value
  }

  return obj[property]
}

export const getStackTrace = (response) => {
  if (response.StackTraceString && response.Source) {
    return response
  }

  return null
}

export const validPin = (event) => {
  isNotIntegerNumber(event)
  let pin = document.getElementById('pin')
  var e = event || window.event
  var key = e?.keyCode || e.which
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    setTimeout(() => {
      pin.type = 'password'
    }, 500)
    pin.type = 'string'
  }
}

export const isNotIntegerNumber = (event) => {
  var e = event || window.event
  var key = e?.keyCode || e.which

  // Allow Ctrl+C and Ctrl+V
  if ((e.ctrlKey && key === 67) || (e.ctrlKey && key === 86)) {
    return // Allow copying and pasting
  }

  if (
    (key !== 8 && // backspace/delete
      key !== 37 && // left arrow
      key !== 39 && // right arrow
      key !== 46 && // delete
      key !== 13 && // enter
      key < 48) ||
    (key > 57 && (key < 96 || key > 105)) ||
    event.key === '`' ||
    event.shiftKey ||
    event.altKey
  ) {
    e.preventDefault()
  }
}

export const isNotNumber = (event) => {
  var e = event || window.event
  var key = e?.keyCode || e.which
  if (
    (key !== 8 && // backspace/delete
      key !== 9 && // tab
      key !== 37 && // left arrow
      key !== 39 && // right arrow
      key !== 46 && // delete
      key !== 13 && // enter
      key !== 110 && // decimal pointer
      key !== 190 && // period
      key !== 188 && // comma
      key < 48) ||
    (key > 57 &&
      (key < 96 ||
        (key > 105 && key < 110) ||
        (key > 110 && key < 188) ||
        (key > 188 && key < 190) ||
        key > 190)) ||
    event.key === '`' ||
    event.shiftKey ||
    event.altKey
  ) {
    e.preventDefault()
  }
}

export const handleReportFile = (axiosResponse, nameFile = 'file') => {
  const downloadUrl = window.URL.createObjectURL(new Blob([axiosResponse.data]))
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', nameFile)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const currencyFormat = (value) =>
  value?.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  })

export const currencyIntegerFormat = (value) => {
  const roundedValue = Math.round(value)
  return roundedValue.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...'
  } else {
    return str
  }
}

export const removeAccents = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
