import BaseProxy from './BaseProxy'
import axios from 'axios'

export class UsersProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/authentication`
  }

  async getUser(searchKey) {
    const response = await axios.get(
      `${this._baseUrl}/users/search/${searchKey}/`
    )
    return response.data
  }

  async getUserById(userId) {
    const response = await axios.get(`${this._baseUrl}/users/${userId}/`)
    return response.data
  }

  async changePassword(userId, passwordChanged) {
    const response = await axios.post(
      `${this._baseUrl}/user/${userId}/changepassword`,
      passwordChanged
    )
    return response.data
  }

  async mergeAccounts(ids) {
    const response = await axios.post(`${this._baseUrl}/mergeaccounts`, ids)
    return response.data
  }

  async getUsers(payload) {
    const response = await axios.post(`${this._baseUrl}/users/search`, payload)
    return response.data
  }

  async loginFacebook(accessToken) {
    const response = await axios.post(`${this._baseUrl}/externallogin`, {
      ClientId: '789473955',
      ClientSecret:
        'bd6b160f772c68dc19ffead1a0e7edb8440491e0f88f2e1b0c08a2c8a8975583',
      Provider: 'Facebook',
      ExternalAccessToken: accessToken
    })

    return response.data
  }
}

export default UsersProxy
