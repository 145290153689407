import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'
import { Button, CircularProgress } from '@material-ui/core'
import { Facebook as FacebookIcon } from '@material-ui/icons'
import FacebookLogin from 'react-facebook-login'
import { useStyles } from './Facebook.style'
import { loginFacebook } from '~/state/modules/login'

export const Facebook = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loadLoginFb, setLoadLoginFb] = useState(false)

  const responseFacebook = async (response) => {
    try {
      await dispatch(
        loginFacebook(response.accessToken, response.picture?.data.url)
      )
      history.push(ROUTES.ADMIN.EVENTS.INDEX)
    } catch (error) {
      console.log(error)
      setLoadLoginFb(false)
    }
  }

  return (
    <>
      <Button
        className={classes.buttonFacebook}
        variant='contained'
        type='submit'
        disabled={loadLoginFb}
        onClick={() => setLoadLoginFb(true)}>
        {loadLoginFb ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <FacebookIcon className={classes.facebookIcon} />
            <span>Continuar con Facebook</span>
          </>
        )}
      </Button>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={loadLoginFb}
        fields='name,email,picture'
        cssClass={classes.facebookDialog}
        callback={responseFacebook}
        icon={<FacebookIcon className={classes.facebookIcon} />}
      />
    </>
  )
}

export default Facebook
