export const PAGEDLIST_ATTEMPT = 'admin/accounts/PAGEDLIST_ATTEMPT'
export const PAGEDLIST_SUCCESS = 'admin/accounts/PAGEDLIST_SUCCESS'
export const PAGEDLIST_FAILURE = 'admin/accounts/PAGEDLIST_FAILURE'

export const CREATE_ACCOUNT_ATTEMPT = 'admin/accounts/CREATE_ACCOUNT_ATTEMPT'
export const CREATE_ACCOUNT_SUCCESS = 'admin/accounts/CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILURE = 'admin/accounts/CREATE_ACCOUNT_FAILURE'

export const REFRESH_TOKEN_ATTEMPT = 'admin/accounts/REFRESH_TOKEN_ATTEMPT'
export const REFRESH_TOKEN_SUCCESS = 'admin/accounts/REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILURE = 'admin/accounts/REFRESH_TOKEN_FAILURE'

export const ENABLE_ACCOUNT_ATTEMPT = 'admin/accounts/ENABLE_ACCOUNT_ATTEMPT'
export const ENABLE_ACCOUNT_SUCCESS = 'admin/accounts/ENABLE_ACCOUNT_SUCCESS'
export const ENABLE_ACCOUNT_FAILURE = 'admin/accounts/ENABLE_ACCOUNT_FAILURE'

export const RESET = 'admin/accounts/RESET'
export const RESET_ACTIONS = 'admin/accounts/RESET_ACTIONS'
export const RESET_PARAMS = 'admin/accounts/RESET_PARAMS'
