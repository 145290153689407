import React from 'react'
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody
} from '@material-ui/core'
import { useStyles } from './ExpandedRowProducers.style'

export const ExpandedRowProducers = ({ organizers }) => {
  const classes = useStyles()

  if (organizers.length === 0) {
    return (
      <Box sx={{ margin: 1 }}>
        <Typography variant='h5' className={classes.emptyTitle} component='div'>
          La cuenta actualmente no se encuentra vinculada a ninguna Productora
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant='h5' className={classes.title} component='div'>
        Datos de la Productora
      </Typography>
      <Table size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>
              Id del Organizador
            </TableCell>
            <TableCell className={classes.cellHead}>Nombre</TableCell>
            <TableCell className={classes.cellHead}>% de Descuento</TableCell>
            <TableCell className={classes.cellHead}>Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizers.map((o, i) => (
            <TableRow key={i} className={classes.bodyStyle}>
              <TableCell title={o.OrganizerId} className={classes.cellRow}>
                {o.OrganizerId}
              </TableCell>
              <TableCell title={o.OrganizerName} className={classes.cellRow}>
                {o.OrganizerName}
              </TableCell>
              <TableCell
                title={o.DiscountPercentage}
                className={classes.cellRow}>
                {o.DiscountPercentage}%
              </TableCell>
              <TableCell className={classes.cellRow}>
                <Typography
                  className={
                    o.Active ? classes.activeStatus : classes.inactiveStatus
                  }>
                  {o.Active ? 'Activo' : 'Inactivo'}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default ExpandedRowProducers
