import React, { useCallback, useEffect, useState } from 'react'
import { useStyles } from '../EventTicketForm.style'
import { Formik } from 'formik'
import {
  Button,
  Checkbox,
  Typography,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Input,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { Save, ArrowDropDown } from '@material-ui/icons'
import * as Yup from 'yup'
import { TicketRules } from 'views/pages/EventsPage/components/TicketRules'
import { isNotNumber, isNotIntegerNumber } from 'views/utils/functions'
import { TicketsComboDialog } from 'views/pages/EventsPage/components/TicketsCombo'
import { ComissionComponentCash } from '../CommissionComponentCash/ComissionComponentCash'
import { COMMISSION_TYPE } from 'config/constants'
import { useDispatch, useSelector } from 'react-redux'
import { loadEvents, loadTicketsOfEvent } from 'state/modules/events'

const validationSchema = () =>
  Yup.object().shape({
    Name: Yup.string()
      .required('Es requerido ingresar nombre del ticket')
      .max(250, "La cantidad máxima de caracteres es de '250'"),
    TicketUsage: Yup.number(),
    Price: Yup.number()
      .typeError('Precio al público debe ser un número')
      .min(0, 'Precio al público no puede ser menor a 0'),
    PercentageCost: Yup.number()
      .typeError('El margen de utilidad debe ser un número')
      .required('Es requerido ingresar margen de utilidad')
      .moreThan(0, 'El margen de utilidad debe ser mayor a 0'),
    CommissionRrpp: Yup.number()
      .typeError('La comisión RRPP debe ser un número')
      .min(0, 'La comisión RRPP no puede ser menor a 0')
      .lessThan(101, 'La comisión RRPP no puede ser mayor a 100'),
    PlatformCommission: Yup.number()
      .typeError('La comisión ticketPass debe ser un número')
      .min(0, 'La comisión RRPP no puede ser menor a 0')
      .lessThan(101, 'La comisión ticketPass no puede ser mayor a 100'),
    Cost: Yup.number()
      .typeError('El valor que se rinde a la productora debe ser un número')
      .required('Es requerido ingresar el valor que se rinde a la productora')
      .moreThan(0, 'El valor que se rinde a la productora debe ser mayor a 0'),
    Remaining: Yup.number()
      .required('Es requerido ingresar cantidad de tickets')
      .integer('Solo se permiten valores enteros')
      .min(0, 'Cantidad no puede ser menor a 0'),
    ComboTickets: Yup.string(),
    Rules: Yup.string()
  })

export const TicketCashForm = ({
  values,
  isCloned,
  getComboTickets,
  getIdsTickets,
  vatList,
  isProducer,
  actionText,
  isOldCommissions,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openDialogTickets, setOpenDialogTickets] = useState(false)

  const [state, setState] = useState({
    checkedC: !values.DisabledDatesForm,
    checkedD: false
  })

  const [disableFormA, setDisableFormA] = useState(values.DisabledDatesForm)
  const toggleA = (values, setFieldValue) => {
    setDisableFormA((d) => !d)
    setFieldValue('DisabledDatesForm', !values.DisabledDatesForm)
  }
  const [hideRules, setHideRules] = useState(true)
  const [newValues, setNewValues] = useState()
  const toggleRules = () => setHideRules((d) => !d)

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const validateNaNValue = (calculation) => {
    if (isNaN(calculation) || !isFinite(calculation)) {
      return 0
    }
    return calculation
  }

  const truncate = (value, positions = 2) => {
    let powerOfTen = Math.pow(10, positions)
    return Math.floor(value * powerOfTen) / powerOfTen
  }

  const round = (value, positions = 2) => {
    let powerOfTen = Math.pow(10, positions)
    return Math.round(value * powerOfTen) / powerOfTen
  }

  const calculateFields = (
    value,
    platformCommission,
    values,
    setFieldValue
  ) => {
    const { Commissions, Cost } = values
    const floatCost = validateNaNValue(parseFloat(Cost))
    const floatValue = validateNaNValue(parseFloat(value))

    //set Commissions
    let newCommissions = Commissions
    if (platformCommission) {
      let resellerWithTaxAmount = 0
      Commissions.forEach((v) => {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          resellerWithTaxAmount += v.CommissionWithTaxAmount
        }
      })

      newCommissions = Commissions.map((v) => {
        if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
          return {
            ...v,
            Commission: floatValue,
            CommissionWithTaxAmount: Math.ceil(
              validateNaNValue(
                (resellerWithTaxAmount + floatCost) *
                  ((floatValue / 100) * (1 + v.CommissionVat))
              )
            )
          }
        } else {
          return {
            ...v
          }
        }
      })
    } else {
      newCommissions = Commissions.map((v) => {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          return {
            ...v,
            CommissionWithTaxAmount: floatValue
          }
        } else if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
          return {
            ...v,
            CommissionWithTaxAmount: Math.ceil(
              validateNaNValue(
                (floatValue + floatCost) *
                  ((v.Commission / 100) * (1 + v.CommissionVat))
              )
            )
          }
        } else {
          return {
            ...v
          }
        }
      })
    }
    setFieldValue('Commissions', newCommissions)

    ///calculated Price
    let sum = 0
    newCommissions.forEach((v) => {
      const withTaxAmount = v.CommissionWithTaxAmount
      if (v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY) {
        sum += withTaxAmount
      }
    })
    let calculatedPrice = sum + floatCost
    setFieldValue('Price', calculatedPrice)

    // PercentageCost Calculated
    const calculatedPercentCost = validateNaNValue(
      (floatCost / calculatedPrice) * 100
    )
    setFieldValue('PercentageCost', calculatedPercentCost)

    // PercentageCommissionReseller Calculated
    setFieldValue(
      'Commissions',
      newCommissions.map((v) => {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          return {
            ...v,
            Commission: validateNaNValue(
              (v.CommissionWithTaxAmount / calculatedPrice) * 100
            )
          }
        } else {
          return {
            ...v
          }
        }
      })
    )

    // TicketPrice & ServiceCharge Calculated
    let totalTicketPrice = 0
    let totalServiceCharge = 0
    newCommissions.forEach((v) => {
      const withTaxAmount = v.CommissionWithTaxAmount
      if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        totalTicketPrice = floatCost + withTaxAmount
      }
      if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
        totalServiceCharge = withTaxAmount
      }
    })
    setFieldValue('TicketPrice', totalTicketPrice)
    setFieldValue('ServiceCharge', totalServiceCharge)
  }

  const calculateFieldsByCost = (value, values, setFieldValue) => {
    const { Commissions } = values
    const floatValue = validateNaNValue(parseFloat(value))

    //set Commissions
    let withTaxAmountReseller = 0
    Commissions.forEach((v) => {
      if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        withTaxAmountReseller = v.CommissionWithTaxAmount
      }
    })
    const newCommissions = Commissions.map((v) => {
      if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
        return {
          ...v,
          CommissionWithTaxAmount: Math.ceil(
            validateNaNValue(
              (withTaxAmountReseller + floatValue) *
                ((v.Commission / 100) * (1 + v.CommissionVat))
            )
          )
        }
      } else {
        return {
          ...v
        }
      }
    })
    setFieldValue('Commissions', newCommissions)

    ///calculated Price
    let sum = 0
    newCommissions.forEach((v) => {
      const withTaxAmount = v.CommissionWithTaxAmount
      if (v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY) {
        sum += withTaxAmount
      }
    })
    let calculatedPrice = sum + floatValue
    setFieldValue('Price', calculatedPrice)

    // PercentageCost Calculated
    const calculatedPercentCost = validateNaNValue(
      (floatValue / calculatedPrice) * 100
    )
    setFieldValue('PercentageCost', calculatedPercentCost)

    // PercentageCommissionReseller Calculated
    setFieldValue(
      'Commissions',
      newCommissions.map((v) => {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          return {
            ...v,
            Commission: validateNaNValue(
              (v.CommissionWithTaxAmount / calculatedPrice) * 100
            )
          }
        } else {
          return {
            ...v
          }
        }
      })
    )

    // TicketPrice & ServiceCharge Calculated
    let totalTicketPrice = 0
    let totalServiceCharge = 0
    newCommissions.forEach((v) => {
      const withTaxAmount = v.CommissionWithTaxAmount
      if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
        totalTicketPrice = floatValue + withTaxAmount
      }
      if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
        totalServiceCharge += withTaxAmount
      }
    })
    setFieldValue('TicketPrice', totalTicketPrice)
    setFieldValue('ServiceCharge', totalServiceCharge)
  }

  const calculatePercentage = (producerComission, commissions) => {
    let total = 0
    const resellerComission = commissions.find(
      (e) => e.CommissionType === COMMISSION_TYPE.RESELLER
    )
    const percentageResellerCommission = resellerComission?.Commission
    total = 100 - round(producerComission) - round(percentageResellerCommission)
    return round(total) === 100 ? 0 : total
  }

  const validateValues = (values, handleSubmit, errors) => {
    const validatedValues = JSON.parse(JSON.stringify(values))
    if (Object.keys(errors).length === 0) {
      validatedValues.DisabledDatesForm = state.checkedC
      validatedValues.PaymentGatewayCommission = 0
      if (
        validatedValues.MaxByReseller !== '' &&
        validatedValues.MaxByReseller != null
      ) {
        validatedValues.MaxByReseller = parseInt(validatedValues.MaxByReseller)
      } else {
        validatedValues.MaxByReseller = null
      }
      validatedValues.Commissions = validatedValues.Commissions.map((v) => {
        return {
          ...v,
          CommissionUsed: v.CommissionType === COMMISSION_TYPE.RESELLER ? 1 : 0,
          Commission: round(v.Commission / 100, 6)
        }
      }).filter((v) => v.CommissionType !== COMMISSION_TYPE.PAYMENT_GATEWAY)
    }
    setNewValues(validatedValues)
    handleSubmit(validatedValues)
  }

  // Being rules section
  const {
    events,
    ruleTickets: tickets,
    actionPending: pendingEvents,
    ruleTicketsPending: pendingTickets
  } = useSelector((state) => state.events)

  const fetchEvents = useCallback(async () => {
    await dispatch(loadEvents())
  }, [dispatch])

  const fetchTicketsOfEvent = useCallback(
    async (eventId) => {
      return await dispatch(loadTicketsOfEvent(eventId))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!hideRules) {
      fetchEvents().then()
    }
  }, [fetchEvents, hideRules])
  // End rules section

  useEffect(() => {
    if (values != null && vatList != null) {
      values.CommissionCash = values.Price - values.Cost
      values.Rules?.trim() !== '' && setHideRules(false)
      if (actionText === 'Crear' && !isCloned) {
        values.Commissions = values.Commissions.map((v) => {
          if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
            return {
              ...v,
              CommissionVat: vatList[0]
            }
          }
          return v
        })
      } else {
        values.Commissions = values.Commissions.map((v) => {
          return {
            ...v,
            Commission: v.Commission * 100
          }
        })
      }
    }
  }, [values, actionText, vatList, isCloned])

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (hideRules) {
          values.Rules = ''
        }
        handleSubmit(newValues)
      }}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            {isOldCommissions && (
              <Alert severity='warning' style={{ margin: 8 }}>
                ATENCIÓN: A este tipo de ticket no se le permite modificar las
                comisiones, ya que corresponde a un ticket creado sin la
                actualización de Comisiones. Si desea cambiar alguna comisión
                deberá editarlo desde la AppManager.
              </Alert>
            )}
            <div className={classes.actionsContainerTop}>
              <Button
                onClick={onCancel}
                disabled={actionSuccess}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  validateValues(values, handleSubmit, errors)
                }}
                disabled={actionPending || actionSuccess}
                color='primary'
                variant='contained'
                startIcon={<Save />}>
                {actionPending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  actionText
                )}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del ticket (visible al usuario)'
                }
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
                autoFocus
              />
            </div>
            {!isOldCommissions && (
              <>
                <div className={classes.containerInputs}>
                  <TextField
                    label={
                      (errors.Cost && touched.Cost && errors.Cost) ||
                      'Valor al que se rinde la productora'
                    }
                    id='standard-start-adornment'
                    style={{ width: 300 }}
                    type='string'
                    name='Cost'
                    error={Boolean(touched.Cost && errors.Cost)}
                    value={round(values.Cost)}
                    onChange={(e) => {
                      handleChange(e)
                      calculateFieldsByCost(
                        e.target.value,
                        values,
                        setFieldValue
                      )
                    }}
                    onBlur={handleBlur}
                    onKeyPress={(e) => isNotNumber(e)}
                    onKeyDown={(e) => isNotNumber(e)}
                    onKeyUp={handleChange}
                    helperText='&zwnj;'
                    inputProps={{ maxLength: 8 }}
                    className={`${classes.fieldCommission} ${classes.fieldCommissionAmount}`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      )
                    }}
                    variant='standard'
                  />
                  <FormControl
                    variant='standard'
                    className={`${classes.formControl} ${classes.formControlCommission}`}>
                    <Input
                      id='standard-adornment-weight'
                      name='PercentageCost'
                      value={validateNaNValue(round(values.PercentageCost))}
                      onChange={handleChange}
                      disabled
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position='end'>%</InputAdornment>
                      }
                      className={`${classes.fieldCommission} ${classes.fieldCommissionPercent}`}
                      aria-describedby='standard-weight-helper-text'
                      onKeyPress={(e) => isNotNumber(e)}
                      onKeyDown={(e) => isNotNumber(e)}
                      inputProps={{
                        'aria-label': 'weight',
                        maxLength: 3
                      }}
                    />
                    <FormHelperText
                      id='standard-weight-helper-text'
                      error={Boolean(errors.PercentageCost)}>
                      {errors.PercentageCost || 'Margen de utilidad'}
                    </FormHelperText>
                  </FormControl>
                </div>
                <ComissionComponentCash
                  label='Comisión RRPP'
                  amountName='CommissionRrppAmount'
                  percentName='CommissionRrpp'
                  helperTextPercent={
                    (errors.CommissionRrpp &&
                      touched.CommissionRrpp &&
                      errors.CommissionRrpp) ||
                    '\u0020'
                  }
                  helperTextAmount='&zwnj;'
                  error={
                    errors.CommissionRrpp &&
                    touched.CommissionRrpp &&
                    errors.CommissionRrpp
                  }
                  handleBlur={handleBlur}
                  commission={values.Commissions.find(
                    (e) => e.CommissionType === COMMISSION_TYPE.RESELLER
                  )}
                  setFieldValue={setFieldValue}
                  values={values}
                  calculateFields={calculateFields}
                  validateNaNValue={validateNaNValue}
                  truncate={truncate}
                  round={round}
                />
                <ComissionComponentCash
                  label='Comisión ticketPass (IVA incluido)'
                  amountName='PlatformCommissionAmount'
                  percentName='PlatformCommission'
                  helperTextAmount='IVA incluido'
                  helperTextPercent={
                    (errors.PlatformCommission &&
                      touched.PlatformCommission &&
                      errors.PlatformCommission) ||
                    'Sin IVA'
                  }
                  error={
                    errors.PlatformCommission &&
                    touched.PlatformCommission &&
                    errors.PlatformCommission
                  }
                  helperTextVat='IVA ticketPass'
                  handleBlur={handleBlur}
                  commission={values.Commissions.find(
                    (e) => e.CommissionType === COMMISSION_TYPE.PLATFORM
                  )}
                  calculatePercentage={calculatePercentage(
                    values.PercentageCost,
                    values.Commissions
                  )}
                  vatList={vatList}
                  setFieldValue={setFieldValue}
                  values={values}
                  calculateFields={calculateFields}
                  isProducer={isProducer}
                  validateNaNValue={validateNaNValue}
                  truncate={truncate}
                  round={round}
                />
              </>
            )}
            <div className={classes.containerPrice}>
              <div
                className={`${classes.containerInputs} ${classes.inputPrice}`}>
                <TextField
                  disabled
                  label={
                    (errors.Price && touched.Price && errors.Price) ||
                    'Precio al público'
                  }
                  name='Price'
                  value={truncate(values.Price)}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  style={{ width: 520 }}
                  error={touched.Price && Boolean(errors.Price)}
                  fullWidth
                  required
                  size='small'
                  variant='outlined'
                  className={classes.field}
                />
                {!isOldCommissions && (
                  <div className={classes.containerInputs}>
                    <Typography variant='h6'>
                      Precio del ticket:&nbsp;
                      <Typography
                        variant='h6'
                        component='span'
                        className={classes.summary}>
                        {values.TicketPrice.toString().split('.')[1]?.length > 2
                          ? round(values.TicketPrice)
                          : values.TicketPrice}
                      </Typography>
                    </Typography>
                    <Typography variant='h6'>
                      Cargo por servicio:&nbsp;
                      <Typography
                        variant='h6'
                        component='span'
                        className={classes.summary}>
                        {values.ServiceCharge.toString().split('.')[1]?.length >
                        2
                          ? truncate(values.ServiceCharge)
                          : values.ServiceCharge}
                      </Typography>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Remaining && touched.Remaining && errors.Remaining) ||
                  'Cantidad'
                }
                name='Remaining'
                type='string'
                onKeyPress={(e) => isNotNumber(e)}
                onKeyDown={(e) => isNotNumber(e)}
                value={values.Remaining}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Remaining && Boolean(errors.Remaining)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label='Cantidad Máxima por RRPP (opcional)'
                name='MaxByReseller'
                type='string'
                value={
                  values.MaxByReseller ? parseInt(values.MaxByReseller) : ''
                }
                onChange={handleChange}
                onBlur={handleBlur}
                onPaste={(e) => e.preventDefault()}
                inputProps={{ maxLength: 5 }}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                error={touched.MaxByReseller && Boolean(errors.MaxByReseller)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
              <InputLabel shrink={true}>
                {(errors.ComboTickets &&
                  touched.ComboTickets &&
                  errors.ComboTickets) ||
                  'Tickets Ids de Combos'}
              </InputLabel>
              <Button
                disableFocusRipple
                onClick={() => setOpenDialogTickets(true)}
                variant='outlined'
                className={classes.textButton}
                endIcon={<ArrowDropDown />}>
                {values.ComboTickets
                  ? values.ComboTickets
                  : 'Seleccione tickets'}
              </Button>
              <TicketsComboDialog
                comboTickets={getComboTickets(values?.ComboTicketModel)}
                idsTickets={getIdsTickets(values.ComboTickets)}
                openDialogTickets={openDialogTickets}
                setOpenDialogTickets={setOpenDialogTickets}
                setFieldValue={setFieldValue}
              />
            </FormControl>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.AllowRemoteSell}
                    onChange={handleChange}
                    name='AllowRemoteSell'
                  />
                }
                label='Permitir Venta Remota?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsTransferable}
                    onChange={handleChange}
                    name='IsTransferable'
                  />
                }
                label='Es transferible?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Active}
                    onChange={handleChange}
                    name='Active'
                  />
                }
                label='Habilitado?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Billable}
                    onChange={handleChange}
                    name='Billable'
                  />
                }
                label='Facturable?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedC}
                    onChange={handleChangeCheck}
                    onClick={() => {
                      toggleA(values, setFieldValue)
                    }}
                    name='checkedC'
                  />
                }
                label='Habilitar fecha de inicio y de fin de venta de ticket?'
              />
            </div>
            <>
              {!disableFormA && (
                <div className={classes.fieldsContainer}>
                  <FormControl variant='outlined' className={classes.fieldRow}>
                    <DatePicker
                      locale='es'
                      id='SaleStartDate'
                      format='DD/MM/YYYY'
                      name='SaleStartDate'
                      value={values.SaleStartDate}
                      label={
                        (errors.SaleStartDate &&
                          touched.SaleStartDate &&
                          errors.SaleStartDate) ||
                        'Fecha de Inicio'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleStartDate', value)
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.SaleStartDate && Boolean(errors.SaleStartDate)
                      }
                      disableToolbar
                      className={classes.field}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                      animateYearScrolling
                    />
                    <TimePicker
                      locale='es'
                      name='SaleStartDate'
                      className={classes.field}
                      value={values.SaleStartDate}
                      label={
                        (errors.SaleStartDate &&
                          touched.SaleStartDate &&
                          errors.SaleStartDate) ||
                        'Hora de Inicio'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleStartDate', value)
                      }}
                      error={
                        touched.SaleStartDate && Boolean(errors.SaleStartDate)
                      }
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  <FormControl variant='outlined' className={classes.fieldRow}>
                    <DatePicker
                      locale='es'
                      id='SaleEndDate'
                      format='DD/MM/YYYY'
                      name='SaleEndDate'
                      value={values.SaleEndDate}
                      label={
                        (errors.SaleEndDate &&
                          touched.SaleEndDate &&
                          errors.SaleEndDate) ||
                        'Fecha de Fin'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleEndDate', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.SaleEndDate && Boolean(errors.SaleEndDate)}
                      disableToolbar
                      className={classes.field}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                      animateYearScrolling
                    />
                    <TimePicker
                      locale='es'
                      name='SaleEndDate'
                      className={classes.field}
                      value={values.SaleEndDate}
                      label={
                        (errors.SaleEndDate &&
                          touched.SaleEndDate &&
                          errors.SaleEndDate) ||
                        'Hora de Fin'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleEndDate', value)
                      }}
                      error={touched.SaleEndDate && Boolean(errors.SaleEndDate)}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>
              )}
            </>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'hasRules'}
                    checked={!hideRules}
                    onClick={toggleRules}
                  />
                }
                label='Reglas?'
              />
            </div>
            <span>
              {!hideRules && (
                <div className={classes.fieldsContainer}>
                  <TicketRules
                    value={values.Rules}
                    events={events}
                    tickets={tickets}
                    pendingEvents={pendingEvents}
                    pendingTickets={pendingTickets}
                    onRequestTicketsOfEvent={(event) =>
                      fetchTicketsOfEvent(event.Id)
                    }
                    onChange={(rules) => {
                      setFieldValue('Rules', rules)
                    }}
                  />
                </div>
              )}
            </span>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={actionSuccess} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                validateValues(values, handleSubmit, errors)
              }}
              disabled={actionPending || actionSuccess}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default TicketCashForm
