import * as types from './types'

// InitialState
const initialState = {
  user: null,
  pending: false,
  error: null
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_ATTEMPT:
      return {
        ...state,
        user: null,
        error: null,
        pending: true
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        pending: false
      }
    case types.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        pending: false,
        error: action.error
      }
    case types.CLOSE_SESSION:
      return {
        ...state,
        user: null,
        pending: false,
        error: null
      }
    case types.RESTORE_SESSION:
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
}
