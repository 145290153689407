import React from 'react'
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody
} from '@material-ui/core'
import { useStyles } from './ExpandedRowMacro.style'

export const ExpandedRowMacro = ({ macroData }) => {
  const classes = useStyles()

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant='h5' className={classes.title} component='div'>
        Datos de la Cuenta
      </Typography>
      <Table size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>Id del Comercio</TableCell>
            <TableCell className={classes.cellHead}>SecretId</TableCell>
            <TableCell className={classes.cellHead}>Frase</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.bodyStyle}>
          <TableRow>
            <TableCell title={macroData.CommerceId} className={classes.cellRow}>
              {macroData.CommerceId}
            </TableCell>
            <TableCell title={macroData.SecretKey} className={classes.cellRow}>
              {macroData.SecretKey}
            </TableCell>
            <TableCell title={macroData.Phrase} className={classes.cellRow}>
              {macroData.Phrase}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

export default ExpandedRowMacro
