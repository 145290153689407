import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    marginBlock: 4,
    marginLeft: 8
  },
  cellRow: {
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingBlock: 4
  },
  cellHead: {
    '&.MuiTableCell-head': {
      backgroundColor: 'grey',
      color: 'white'
    }
  },
  bodyStyle: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  },
  activeStatus: {
    fontSize: '14px',
    backgroundColor: 'green',
    color: 'white',
    paddingInline: '10px',
    paddingBlock: '4px',
    borderRadius: '18px',
    width: 'min-content'
  },
  inactiveStatus: {
    fontSize: '14px',
    backgroundColor: 'red',
    color: 'white',
    paddingInline: '10px',
    paddingBlock: '4px',
    borderRadius: '18px',
    width: 'min-content'
  },
  buttonToken: {
    textTransform: 'none',
    paddingBlock: 3,
    paddingInline: 12,
    borderRadius: 8
  },
  loadingCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

export default useStyles
