import axios from 'axios'
import BaseProxy from './BaseProxy'

export class AccountsProxy extends BaseProxy {
  constructor() {
    super()
    this._baseUrl = `${this._baseUrl}/api/payment-gateway-account`
  }

  async get(searchParams, pageNumber, pageSize, orderBy, order) {
    const response = await axios.get(
      `${this._baseUrl}/paged${this._makeUrlParams({
        searchParams,
        pageNumber,
        pageSize,
        order,
        orderBy
      })}`
    )

    return response.data
  }

  async createAccount(payload) {
    const response = await axios.post(`${this._baseUrl}/macro-click`, payload)

    return response.data
  }

  async refreshToken(accountId) {
    const response = await axios.post(
      `${this._baseUrl}/${accountId}/mercado-pago/refresh-token`
    )

    return response.data
  }

  async enableAccount(organizerId, accountId) {
    const response = await axios.post(
      `${this._baseUrl}/organizers/${organizerId}/accounts/${accountId}/enable`
    )

    return response.data
  }
}

export default AccountsProxy
