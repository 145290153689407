import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { PaperContainer } from '~views/shared'
import moment from 'moment'
import { refreshToken, enableAccount } from '~/state/modules/accounts'
import { useStyles } from './AccountsPage.style'
import { loadOrganizer } from '~/state/modules/organizers'
import { useHistory } from 'react-router-dom'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { ROUTES } from 'views/routes'

export const AccountsPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { organizerId } = match.params
  const { organizer, pending } = useSelector((state) => state.organizers)
  const { pendingRefresh } = useSelector((state) => state.accounts)

  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(organizerId))
    }

    fetchData().then()
  }, [dispatch, organizerId])

  const createRow = (
    label,
    value,
    formatter,
    customStyle = null,
    title = ''
  ) => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          className={`${classes.tableCell} ${classes.tableCellHeader}`}
          variant='head'>
          {label}
        </TableCell>
        <TableCell
          title={title}
          className={`${classes.tableCell} ${
            customStyle !== null && customStyle
          }`}>
          {formatter ? formatter(value) : value}
        </TableCell>
      </TableRow>
    )
  }

  const createEnableAccountButton = (organizerId, itemId, active) => {
    return (
      <Button
        variant='outlined'
        disabled={active}
        className={classes.buttonMp}
        onClick={() => handleEnableAccount(organizerId, itemId)}>
        {pendingRefresh ? (
          <div className={classes.loadingCard}>
            <CircularProgress size={24} style={{ marginRight: '1rem' }} />
            <Typography variant={'caption'}>Cargando ...</Typography>
          </div>
        ) : (
          'Activar'
        )}
      </Button>
    )
  }

  const getDateClasses = (expirationDate) => {
    const isAfter = moment().isAfter(expirationDate)
    const isSameOrAfter6Days = moment()
      .add(6, 'days')
      .isSameOrAfter(expirationDate)

    if (isAfter) {
      return classes.inactiveRow
    } else if (isSameOrAfter6Days) {
      return classes.warningRow
    }
    return ''
  }

  const daysDifferenceTitle = (expirationDate) => {
    const daysDifference = moment(expirationDate).diff(moment(), 'days')
    const hoursDifference = moment(expirationDate).diff(moment(), 'hours') % 24

    if (daysDifference > 0 || hoursDifference > 0) {
      return `Está a ${daysDifference} días y ${hoursDifference} horas de vencer`
    }
    return ''
  }

  const handleRefreshToken = async (mpId) => {
    try {
      await dispatch(refreshToken(mpId))
      showSnackbarSuccess(
        'Se ha actualizado la cuenta correctamente!',
        null,
        4000
      )
      window.location.reload()
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleEnableAccount = async (organizerId, accountId) => {
    try {
      await dispatch(enableAccount(organizerId, accountId))
      showSnackbarSuccess('Se ha habilitado la cuenta correctamente!')
      await dispatch(loadOrganizer(organizerId))
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleBack = () => {
    history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)
  }

  return (
    <>
      {organizer && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${organizer.Name} (${organizerId})`}{' '}
        </Typography>
      )}
      <PaperContainer>
        {pending ? (
          <>
            <Typography variant='h5' className={classes.eventTitle}>
              Mis Cuentas
            </Typography>
            <CircularProgress size={24} color='secondary' />
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography variant='h5' className={classes.eventTitle}>
                Mis Cuentas
              </Typography>
              <Button
                onClick={handleBack}
                color='primary'
                startIcon={<ArrowBack />}>
                Volver
              </Button>
            </div>
            {organizer?.Accounts &&
              organizer?.Accounts.sort(
                (a, b) => (b.Account?.Id || 0) - (a.Account?.Id || 0)
              ).map((item, i) => {
                var cuit = item.Account.Cuit ? item.Account?.Cuit : '-'
                var razonSocial = item.Account.RazonSocial
                  ? item.Account.RazonSocial
                  : '-'
                var email = item.Account.Email ? item.Account.Email : '-'
                var expirationDate =
                  moment(item.Account?.ExpirationDate).diff(moment(), 'hours') <
                  0
                    ? moment(item.Account?.ExpirationDate).format(
                        'DD/MM/YYYY HH:mm'
                      ) + ' - (Vencido)'
                    : moment(item.Account?.ExpirationDate).format(
                        'DD/MM/YYYY HH:mm'
                      ) +
                      ` (${moment(item.Account?.ExpirationDate).diff(
                        moment(),
                        'days'
                      )}) días`
                return item.PaymentGatewayName === 'MacroClick' ? (
                  <div key={i}>
                    <Table>
                      <TableBody>
                        {createRow(
                          'Plataforma',
                          item.PaymentGatewayName,
                          () => (
                            <Typography>{`(${item.PaymentGatewayId}) ${item.PaymentGatewayName}`}</Typography>
                          ),
                          classes.macroCell
                        )}
                        {createRow('CuentaId', item.Account?.Id)}
                        {createRow(
                          'Estado',
                          item?.Active ? 'Activo' : 'Inactivo',
                          (value) => (
                            <Typography
                              className={
                                value === 'Activo'
                                  ? classes.activeStatus
                                  : classes.inactiveStatus
                              }>
                              {item?.Active ? 'Activo' : 'Inactivo'}
                            </Typography>
                          )
                        )}
                        {createRow('Acciones', '', () =>
                          createEnableAccountButton(
                            organizer?.Id,
                            item.Id,
                            item?.Active
                          )
                        )}
                        {createRow('Id del Comercio', item.Account?.CommerceId)}
                        {createRow('Cuit', cuit)}
                        {createRow('Razón Social', razonSocial)}
                      </TableBody>
                    </Table>
                    <br />
                  </div>
                ) : (
                  <div key={i}>
                    <Table>
                      <TableBody>
                        {createRow(
                          'Plataforma',
                          item.PaymentGatewayName,
                          () => (
                            <Typography>{`(${item.PaymentGatewayId}) ${item.PaymentGatewayName}`}</Typography>
                          ),
                          classes.mpCell
                        )}
                        {createRow('CuentaId', item.Account?.Id)}
                        {createRow(
                          'Estado',
                          item?.Active ? 'Activo' : 'Inactivo',
                          (value) => (
                            <Typography
                              className={
                                value === 'Activo'
                                  ? classes.activeStatus
                                  : classes.inactiveStatus
                              }>
                              {item?.Active ? 'Activo' : 'Inactivo'}
                            </Typography>
                          )
                        )}
                        {createRow('MercadoPago UserId', item.Account?.UserId)}
                        {createRow('Nombre', item.Account?.Name)}
                        {createRow('Email', email)}
                        {createRow(
                          'Fecha de Expiración',
                          expirationDate,
                          null,
                          getDateClasses(item.Account?.ExpirationDate),
                          daysDifferenceTitle(item.Account?.ExpirationDate)
                        )}
                        {createRow('Cuit', cuit)}
                        {createRow('Razón Social', razonSocial)}
                        {createRow('Acciones', '', () => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 8
                            }}>
                            <Button
                              variant='outlined'
                              className={classes.buttonMp}
                              onClick={() =>
                                handleRefreshToken(item.Account?.Id)
                              }>
                              {pendingRefresh ? (
                                <div className={classes.loadingCard}>
                                  <CircularProgress
                                    size={24}
                                    style={{ marginRight: '1rem' }}
                                  />
                                  <Typography variant={'caption'}>
                                    Cargando ...
                                  </Typography>
                                </div>
                              ) : (
                                'Actualizar Token'
                              )}
                            </Button>
                            {createEnableAccountButton(
                              organizer?.Id,
                              item.Id,
                              item?.Active
                            )}
                          </div>
                        ))}
                      </TableBody>
                    </Table>
                    <br />
                  </div>
                )
              })}
            <div className={classes.actionsContainer}>
              <Button onClick={handleBack} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default AccountsPage
