import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './AccountsIndexPage.style'
import { Button, TextField } from '@material-ui/core'
import { Add, ExpandMore, ExpandLess } from '@material-ui/icons'
import { loadPage } from '~/state/modules/accounts'
import { ExpandedRowMacro } from './ExandedRowMacro'
import { ExpandedRowProducers } from './ExandedRowProducers'
import { ExpandedRowMp } from './ExpandedRowMp/ExpandedRowMp'
import moment from 'moment'
import {
  TableGrid,
  createColumn,
  createCustomColumn,
  TableGridOrder
} from '~/views/shared'
import history from '~/config/history'
import { ROUTES } from '~/views/routes'

const makeColumns = (
  classes,
  expandedPlataforma,
  expandedProductoras,
  setExpandedPlataforma,
  setExpandedProductoras,
  setIsMacro,
  setIsMp,
  setIsProducers,
  setSelected
) => {
  return [
    createColumn('Id', 'Id'),
    createColumn('Cuit', 'CUIT'),
    createColumn('RazonSocial', 'Razón Social'),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            setSelected(c)
            setIsMacro(false)
            setIsMp(false)
            setIsProducers(true)
            setExpandedPlataforma(null)
            expandedProductoras === c.Id
              ? setExpandedProductoras(null)
              : setExpandedProductoras(c.Id)
          }}
          endIcon={
            expandedProductoras === c.Id ? <ExpandLess /> : <ExpandMore />
          }
          className={classes.button}>
          Mostrar Más
        </Button>
      ),
      'Productoras Asociadas'
    ),
    createCustomColumn(
      (c) => (
        <Button
          onClick={() => {
            setSelected(c)
            setIsProducers(false)
            setExpandedProductoras(null)
            if (c.Platform === 'MacroClick') {
              setIsMp(false)
              setIsMacro(true)
            } else {
              setIsMacro(false)
              setIsMp(true)
            }
            expandedPlataforma === c.Id
              ? setExpandedPlataforma(null)
              : setExpandedPlataforma(c.Id)
          }}
          endIcon={
            expandedPlataforma === c.Id ? <ExpandLess /> : <ExpandMore />
          }
          className={classes.button}>
          {c.Platform}
        </Button>
      ),
      'Plataforma'
    )
  ]
}
const makeFooterActions = () => [
  {
    title: 'Crear Cuenta Macro',
    icon: <Add />,
    action: () => {
      history.push(ROUTES.ADMIN.ACCOUNTS.CREATE)
    }
  }
]

export const AccountsIndexPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pagedList, pending, reqParams } = useSelector(
    (state) => state.accounts
  )
  const [expandedProductoras, setExpandedProductoras] = useState(null)
  const [expandedPlataforma, setExpandedPlataforma] = useState(null)
  const [isProducers, setIsProducers] = useState(false)
  const [isMacro, setIsMacro] = useState(false)
  const [isMp, setIsMp] = useState(false)
  const [selected, setSelected] = useState()
  const [search, setSearch] = useState(reqParams?.searchParams || '')

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      performSearch()
    }
  }

  const performSearch = () => {
    dispatch(
      loadPage({
        searchParams: search,
        pageNumber: 0,
        pageSize: 10,
        orderBy: reqParams?.orderBy || 'Id',
        order: reqParams?.order || TableGridOrder.DESC
      })
    )
  }

  const getRowClasses = (item) => {
    const isAfter = moment().isAfter(item?.ExpirationDate)
    const isSameOrAfter6Days = moment()
      .add(6, 'days')
      .isSameOrAfter(item?.ExpirationDate)

    if (item.Platform === 'MercadoPago') {
      if (isAfter) {
        return classes.inactiveRow
      } else if (isSameOrAfter6Days) {
        return classes.warningRow
      }
    }
    return ''
  }

  const makeSearchForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          fullWidth
          className={classes.input}
          placeholder='Filtrar por Nombre'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={handleSearchKeyPress}
        />
        <Button variant='contained' color='primary' onClick={performSearch}>
          Buscar
        </Button>
      </div>
    )
  }

  return (
    <TableGrid
      title='Listado de Pasarelas de Pagos'
      rowClasses={(x) => getRowClasses(x)}
      columns={makeColumns(
        classes,
        expandedPlataforma,
        expandedProductoras,
        setExpandedPlataforma,
        setExpandedProductoras,
        setIsMacro,
        setIsMp,
        setIsProducers,
        setSelected
      )}
      footerActions={makeFooterActions()}
      searchForm={makeSearchForm}
      searchParams={search}
      loadItemsPage={(x) => {
        dispatch(
          loadPage({
            searchParams: search,
            pageNumber: x.pageNumber || reqParams?.pageNumber,
            pageSize: x.pageSize,
            orderBy: x.orderBy,
            order: x.order
          })
        )
      }}
      isLoading={pending}
      pagedList={pagedList}
      keyName='Id'
      pageSize={reqParams?.pageSize || 10}
      order={reqParams?.order || TableGridOrder.DESC}
      orderBy={reqParams?.orderBy || 'Id'}
      useNewFormat
      expandedRow={expandedPlataforma || expandedProductoras}
      ExpandedRowComponent={
        isProducers ? (
          <ExpandedRowProducers organizers={selected?.Organizers} />
        ) : isMacro ? (
          <ExpandedRowMacro macroData={selected} />
        ) : (
          isMp && <ExpandedRowMp mpData={selected} />
        )
      }
    />
  )
}

export default AccountsIndexPage
