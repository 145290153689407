import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Box,
  Table,
  Button,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  CircularProgress
} from '@material-ui/core'
import { refreshToken } from '~/state/modules/accounts'
import moment from 'moment'
import { useStyles } from './ExpandedRowMp.style'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const ExpandedRowMp = ({ mpData }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pendingRefresh } = useSelector((state) => state.accounts)
  const { Id, UserId, Email, ExpirationDate } = mpData
  const daysDifference = moment(ExpirationDate).diff(moment(), 'days')
  const isBefore = moment().isBefore(ExpirationDate)

  const handleRefreshToken = async () => {
    try {
      await dispatch(refreshToken(Id))
      showSnackbarSuccess('Se ha actualizado la cuenta correctamente!')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant='h5' className={classes.title} component='div'>
        Datos de la Cuenta
      </Typography>
      <Table size='small' aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellHead}>UserId</TableCell>
            <TableCell className={classes.cellHead}>Email</TableCell>
            <TableCell className={classes.cellHead}>
              Fecha de Expiración
            </TableCell>
            <TableCell className={classes.cellHead}>Estado</TableCell>
            <TableCell className={classes.cellHead}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.bodyStyle}>
          <TableRow>
            <TableCell className={classes.cellRow}>{UserId}</TableCell>
            <TableCell className={classes.cellRow}>{Email}</TableCell>
            <TableCell
              className={classes.cellRow}
              title={
                daysDifference > 0
                  ? `Está a ${daysDifference} dias de vencer`
                  : ''
              }>
              {moment(ExpirationDate).format('DD/MM/YYYY HH:mm')}
            </TableCell>
            <TableCell className={classes.cellRow}>
              <Typography
                className={
                  isBefore ? classes.activeStatus : classes.inactiveStatus
                }>
                {isBefore ? 'Activo' : 'Inactivo'}
              </Typography>
            </TableCell>
            <TableCell className={classes.cellRow}>
              <Button
                variant='outlined'
                className={classes.buttonToken}
                onClick={handleRefreshToken}>
                {pendingRefresh ? (
                  <div className={classes.loadingCard}>
                    <CircularProgress
                      size={24}
                      style={{ marginRight: '1rem' }}
                    />
                    <Typography variant={'caption'}>Cargando ...</Typography>
                  </div>
                ) : (
                  'Actualizar Token'
                )}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

export default ExpandedRowMp
