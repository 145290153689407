import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex'
  },
  tableCell: {
    flexGrow: 1,
    fontWeight: 'bold',
    overflow: 'hidden',
    padding: '12px !important'
  },
  tableCellHeader: {
    backgroundColor: '#eaeaea',
    maxWidth: '200px',
    minWidth: '200px',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginTop: theme.spacing(1)
  },
  imagePickerContainer: {
    display: 'flex',
    flexDirection: 'row',

    marginBottom: theme.spacing(2)
  },
  imagePicker: {
    flexGrow: '4',
    height: 200,
    margin: '0 auto',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'lightgray',
    [theme.breakpoints.up('md')]: {
      height: 300
    }
  },
  imagePickerSideBar: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2)
  },
  imagePickerInfo: {
    flexGrow: '1'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  eventTitle: {
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  activeStatus: {
    fontSize: '14px',
    backgroundColor: 'green',
    color: 'white',
    paddingInline: '10px',
    paddingBlock: '4px',
    borderRadius: '18px',
    width: 'min-content'
  },
  inactiveStatus: {
    fontSize: '14px',
    backgroundColor: 'red',
    color: 'white',
    paddingInline: '10px',
    paddingBlock: '4px',
    borderRadius: '18px',
    width: 'min-content'
  },
  macroCell: {
    backgroundColor: '#0F2643',
    '& p': {
      color: 'white',
      fontSize: 19,
      marginBottom: -2
    }
  },
  mpCell: {
    backgroundColor: '#009EE3',
    '& p': {
      color: 'white',
      fontSize: 19,
      marginBottom: -2
    }
  },
  warningRow: {
    backgroundColor: '#fec950',
    '&:hover': {
      backgroundColor: '#F7B217 !important'
    }
  },
  inactiveRow: {
    backgroundColor: '#ffcccc',
    '&:hover': {
      backgroundColor: '#fcb0b1 !important'
    }
  },
  buttonMp: {
    textTransform: 'none',
    paddingBlock: 3,
    paddingInline: 12,
    borderRadius: 8
  },
  loadingCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

export default useStyles
