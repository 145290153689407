import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing()
  },
  fieldRow: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  fieldRowSelect: {
    marginBlock: theme.spacing()
  },
  textButton: {
    textTransform: 'none',
    height: 50,
    marginTop: 10,
    marginInline: theme.spacing(),
    marginBottom: 5,
    justifyContent: 'space-between',
    width: '100%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  actionsContainerTop: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: -theme.spacing(4),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  },
  ticketUsageStyle: {
    marginRight: theme.spacing(2)
  },
  containerInputs: {
    marginLeft: '10px',
    marginBlock: '10px',
    '& .Mui-disabled': {
      color: 'gray',
      '& input': {
        backgroundColor: '#f3f3f3'
      },
      '& p': {
        paddingBlock: 4,
        backgroundColor: '#f3f3f3'
      }
    }
  },
  formControl: {
    width: 200,
    marginTop: 18,
    marginLeft: 24
  },
  formControlCommission: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: 5
    }
  },
  fieldCommission: {
    '& input': {
      textAlign: 'end'
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'end'
    },
    '& .MuiInput-formControl': {
      marginTop: '20px'
    }
  },
  fieldCommissionAmount: {
    '& p': {
      marginRight: -8
    }
  },
  fieldCommissionPercent: {
    '& p': {
      marginLeft: -8,
      padding: 8
    }
  },
  checkBox: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '201px',
      marginTop: '-10px',
      marginRight: '-330px'
    }
  },
  textCheckBox: {
    color: 'grey'
  },
  commissionVat: {
    width: 150,
    marginLeft: 25,
    marginTop: 22
  },
  containerPrice: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputPrice: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: 0,
    marginBottom: 0
  },
  summary: {
    fontWeight: 'bold'
  },
  roundButton: {
    marginInline: theme.spacing()
  }
}))

export default useStyles
